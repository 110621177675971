import {HttpClient} from '@angular/common/http';
import {Directive} from '@angular/core';
import {GridRequest} from '@common/globals/gridRequest';
import {GridResponse} from '@common/globals/gridResponse';
import {getFilterDates} from '@common/statics/getFilterDates';
import {saveFile} from '@common/statics/saveFile';
import {setResponseMessage} from '@common/statics/setResponseMessage';
import {
  BookingDayDTO,
  CollectionBooking,
  CollectionExportRequest,
  CollectionHistoryDTO,
  CollectionItemsDTO,
  CollectionLineDTO,
  ExportTypes,
  NewCollectionRequestDTO,
  ScheduledCallHistoryDTO,
} from '@common/ts/collectionInterfaces';
import {
  CollectionsBackofficeRequest,
  ItemLocation,
  ItemLocationOfVendorNumber,
  PackageTypes,
  PossibleValuesDTO,
} from '@common/ts/interfaces';
import {map, Observable} from 'rxjs';

import texts from './collections';

@Directive()
export abstract class CollectionsInterface {

  constructor(protected http: HttpClient) {
    this.http = http;
  }

  protected fetchPackageTypes(id: string, url: string, byCollection = false): Observable<PackageTypes[]> {
    return this.http.get<PackageTypes[]>(`${url}/packageTypes/available${this.getPackageTypeURL(byCollection, id)}`,
      setResponseMessage({errorMessage: texts.packageLoadFailed}));
  }

  private getPackageTypeURL(byCollection: boolean, id: string): string {
    return byCollection ? `/byCollection?collectionHeaderId=${id}` : `?itemLocationId=${id}`;
  }

  protected export(request: GridRequest,
                   ids: string[],
                   serviceUrlPath: string,
                   name: string,
                   all: boolean,
                   type: ExportTypes): Observable<void> {
    const requestBody = <CollectionExportRequest>{
      request,
      excludedIds: all ? ids : undefined,
      includedIds: !all ? ids : undefined,
    };
    return this.http.post(`${serviceUrlPath}/export/csv/${type}`, requestBody,
      {responseType: 'blob', ...setResponseMessage({errorMessage: texts.exportFailed})}).pipe(map((response) => {
      saveFile(response, name, 'csv');
    }));
  }

  private mapPackages(packages: Record<PackageTypes, number>): CollectionItemsDTO[] {
    return Object.entries(packages).map(([id, value]) => ({
      packageType: id as PackageTypes,
      quantity: value,
    }));
  }

  protected requestNewCollection(itemLocationId: string,
                                 requestedCollectionDate: string,
                                 isPriority: boolean,
                                 pickupPackages: Record<PackageTypes, number>,
                                 dropOffPackages: Record<PackageTypes, number>,
                                 url: string): Observable<boolean> {
    return this.http.post<boolean>(`${url}/bookCollectionRequest`,
      this.getCollectionRequestDTO(itemLocationId, requestedCollectionDate, isPriority, dropOffPackages, pickupPackages),
      setResponseMessage({
        message: texts.saveSuccess,
        errorMessage: texts.saveFailed,
      }));
  }

  public getCollectionRequestDTO(itemLocationId: string, requestedCollectionDate: string, isPriority: boolean,
                                 dropOffPackages: Record<PackageTypes, number>,
                                 pickupPackages: Record<PackageTypes, number>, id: string = null): NewCollectionRequestDTO {
    return {
      collectionHeader: {
        id,
        isPriority,
        originalReference: null,
        itemLocationId,
        requestedCollectionDate: new Date(requestedCollectionDate).toISOString(),
      },
      itemsToDropOff: this.mapPackages(dropOffPackages),
      itemsToPickup: this.mapPackages(pickupPackages),
    };
  }

  protected constructCollectionsRequest(request: GridRequest): CollectionsBackofficeRequest {
    const createdOnPredicate = (prop: string): boolean => prop === 'created_on',
      requestBody: CollectionsBackofficeRequest = {request},
      createdOnFilterIndex = request.filter.findIndex(({prop}) => createdOnPredicate(prop));
    if (createdOnFilterIndex > -1) {
      const {intervalStart, intervalEnd} = getFilterDates(request.filter, false);
      requestBody.intervalStart = intervalStart;
      requestBody.intervalEnd = intervalEnd;
      request.filter = request.filter.filter(({prop}) => !createdOnPredicate(prop));
    }
    return requestBody;
  }

  protected fetchCollectionBookingGridData(request: GridRequest, url: string): Observable<GridResponse<CollectionBooking>> {
    return this.http.post<GridResponse<CollectionBooking>>(`${url}/getCollectionBookings`,
      this.constructCollectionsRequest(request));
  }

  abstract getPackageTypes(itemLocationId: string): Observable<PackageTypes[]>;

  abstract exportGrid(request: GridRequest, ids: string[], name: string, all: boolean, type: ExportTypes): Observable<void>;

  abstract getCollectionBookingGrid(request: GridRequest): Observable<GridResponse<CollectionBooking>>;

  abstract getLineItemsGrid(request: GridRequest): Observable<GridResponse<CollectionLineDTO>>;

  abstract bookNewCollection(vendorNumber: string,
    collectionDate: string,
    isPriority: boolean,
    pickupPackages: Record<number, number>,
    dropOffPackages: Record<number, number>,
    id: string): Observable<boolean>;

  abstract getAvailableBookingDays(locationId: string): Observable<BookingDayDTO[]>;

  abstract getItemLocationsOfCompanies(companyIds: string[]): Observable<ItemLocation[]>;

  abstract getItemLocationsOfVendorNumber(_vendorNumber: string): Observable<ItemLocationOfVendorNumber[]>;

  abstract getItemLocations(): Observable<ItemLocation[]>;

  abstract getCompanies(): Observable<PossibleValuesDTO<string>[]>;

  abstract getCallHistory(itemLocationId: string): Observable<ScheduledCallHistoryDTO[]>;

  abstract getCollectionHistory(itemLocationId: string): Observable<CollectionHistoryDTO[]>;

}
