import {getRelativeDates} from '@common/statics/getRelativeDates';
import {FilterDates, FilterValueDTO} from '@common/ts/interfaces';
import {endOfDay, startOfDay} from 'date-fns';
import {fromZonedTime, toZonedTime} from 'date-fns-tz';

export function getFilterDates(filters: FilterValueDTO[], isDateTime: boolean): FilterDates {
  let intervalStart: Date = null,
    intervalEnd: Date = null;
  for (const {operation, relativeValue, value} of filters) {
    const dates = getRelativeDates(relativeValue);
    if (dates) {
      intervalStart = startOfDay(dates.dateFrom);
      intervalEnd = endOfDay(dates.dateTo);
    } else if (operation === 'less' || operation === 'less_or_equal') {
      intervalEnd = isDateTime ? value : endOfDay(toZonedTime(value, 'UTC'));
    } else if (operation === 'greater' || operation === 'greater_or_equal') {
      intervalStart = isDateTime ? value : startOfDay(toZonedTime(value, 'UTC'));
    }
  }
  return {
    intervalStart: fromZonedTime(intervalStart, 'UTC'),
    intervalEnd: fromZonedTime(intervalEnd, 'UTC'),
  };
}
